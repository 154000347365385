import React from 'react'
import { FaLinkedin } from 'react-icons/fa6'

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    'event_callback': callback
  })
  return false
}

const handleButtonClick = () => {
  gtag_report_conversion('https://t.ly/1Mjqf')
}

const Footer = () => {
  const year = new Date()
  return (
    <footer>
      <div className="footer-top c-dark">
        <div className="container">
          <div className="s-100"></div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-6 flex jcc aic">
              <p>Якщо у вас є запитання, пропозиції або ви хочете долучитися до нашої команди, будь ласка, зв'яжіться з нами. Ми відкриті для будь-яких питань та готові відповісти на них. Ваша думка та підтримка є важливими для нас. Зв'язатися з командою Advogram можна через електронну пошту, соціальні мережі або залишивши повідомлення через форму зворотного зв'язку на нашому сайті.</p>
            </div>
            <div className="col-lg-4 flex jcc aic">
              <a href="https://t.ly/1Mjqf" target="blank" onClick={handleButtonClick} className='button bg-gold'>Зв'язатися</a>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <div className="s-100"></div>
        </div>
      </div>
      <div className="footer-bottom bg-light">
        <div className="container">
          <div className="s-80"></div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <p className='c-dark'>Слідкуйте за нами у соціальних мережах, щоб бути в курсі останніх новин та оновлень Advogram.<br />Приєднуйтесь до нашої спільноти у LinkedIn.
              </p>
              <a href="https://www.linkedin.com/company/advogram">
                <FaLinkedin size={24} className='c-dark' />
              </a>
            </div>
            <div className="m-40"></div>
            <div className="col-lg-2"></div>
            <div className="col-lg-4">
              <p className='c-dark'>
                ГО «АДВОГРАМ»,<br />
                ЄДРПОУ: 44906102<br />
                Директор: Поліна Житнюк<br /><br /><br />
                Advogram © {year.getFullYear()} - Всі права захищено.
              </p>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <div className="s-60"></div>
        </div>
      </div>
    </footer>
  )
}

export {Footer}
