import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../parts/Header'
import volunteer from '../assets/img/volunteer.webp'
import volpic from '../assets/img/volpic.webp'
import help from '../assets/img/help.webp'

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    'event_callback': callback
  })
  return false
}

const handleButtonClick = () => {
  gtag_report_conversion('https://t.ly/atak9')
}

const Volunteers = () => {
  return (
    <>
      <Helmet>
        <title>Advogram | Долучайтесь як Волонтер до Advogram</title>
        <meta name="description" content="Внесіть свій вклад у захист прав громадян. Advogram шукає активних, мотивованих волонтерів, готових долучитися до нашої місії. Якщо ви маєте навички у сферах IT, маркетингу, юридичних послуг, дизайну чи комунікацій, ваша допомога буде надзвичайно цінною для нас" />
        <meta name="keywords" content="ADVOGRAM, Долучайтесь як Волонтер до Advogram, Захист прав громадян, Волонтерська допомога, ІТ розробка, Маркетинг в соціальних мережах, Дизайн, Юридична підтримка, Форма волонтерства, Особистий розвиток, Станьте частиною команди, Подати заявку на волонтерство" />
        <meta name="theme-color" content="#0C1125" />
        <body className='page' />
      </Helmet>
      <Header back={volunteer} />
      <div id='volunteer'>
        <div className="h-156"></div>
        <div className='container tc'>
          <h3 className='title-intro c-gold w-80 ma'>Долучайтесь як Волонтер до Advogram</h3>
        </div>
        <div className="h-156"></div>
      </div>
      <section id="mission">
        <div className="container">
          <div className="s-120"></div>
          <h3 className='title-sub c-gold w-80 ma my-3 tc'>Як Ви Можете Допомогти</h3>
          <p className='c-dark w-70 ma tc'>Внесіть свій вклад у захист прав громадян</p>
          <div className="s-60"></div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <img src={volpic} alt='Станьте Частиною Advogram як Адвокат' width={400} height={360} className='obfc' />
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5 flex jcc aic">
              <div>
                <h3 className='title-sub c-dark my-3'>Долучайтесь як Волонтер до Advogram</h3>
                <div className="s-10"></div>
                <p className='c-dark'>
                  Advogram шукає активних, мотивованих волонтерів, готових долучитися до нашої місії. Якщо ви маєте навички у сферах IT, маркетингу, юридичних послуг, дизайну чи комунікацій, ваша допомога буде надзвичайно цінною для нас
                </p>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <div className="s-100"></div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 flex jcc aic">
              <div>
                <h3 className='title-sub c-dark my-3'>Області, де Ваша Допомога Найбільш Потрібна</h3>
                <div className="s-10"></div>
                <h4 className='title-mini c-gold my-3'>Розробка та IT:</h4>
                <p className='c-dark'>
                  Допомога у розробці та підтримці веб-сайту та мобільного додатку.
                </p>
                <h4 className='title-mini c-gold my-3'>Маркетинг та Соціальні Мережі:</h4>
                <p className='c-dark'>
                  Продвижение Advogram в соціальних мережах та інших медіа.
                </p>
                <h4 className='title-mini c-gold my-3'>Дизайн:</h4>
                <p className='c-dark'>
                  Створення візуальних матеріалів для веб-сайту та рекламних кампаній.
                </p>
                <h4 className='title-mini c-gold my-3'>Юридична Підтримка:</h4>
                <p className='c-dark'>
                  Консультації та юридична допомога команді та користувачам.
                </p>
              </div>
            </div>
            <div className="col-lg-6 flex jcc">
              <img src={help} alt='Станьте Частиною Advogram як Адвокат' width={400} height={480} className='obfc' />
            </div>
          </div>
        </div>
        <div className="s-120"></div>
        <h3 className='title-sub c-gold w-80 ma my-3 tc'>Чому Варто Долучитися до Нас</h3>
        <p className='c-dark w-70 ma tc'>Волонтерство в Advogram - це не тільки можливість допомогти іншим, але й чудовий шанс для особистісного розвитку, набуття нового досвіду та знайомства з однодумцями. Ви станете частиною команди, яка робить реальний вплив на суспільство.</p>
        <div className="s-100">
      </div>
    </section>
    <div className="bg-light">
      <div className="s-120"></div>
      <h3 className='title-sub c-gold w-80 ma my-3 tc'>Станьте Частиною Нашої Команди</h3>
      <p className='c-dark w-70 ma tc'>Щоб стати волонтером Advogram, заповніть форму нижче. Розкажіть нам про себе, свої навички та як ви бачите свій внесок у проект. Ми з нетерпінням чекаємо на вашу заявку!</p>
      <div className="s-120"></div>
    </div>
    <div id="beav">
      <div className="s-120"></div>
      <div className="container tc">
        <h3 className='title-section c-white w-80 ma my-3 tc'>Подати Заявку на Волонтерство</h3>
        <div className="s-60"></div>
        <a href="https://t.ly/atak9" target="blank" onClick={handleButtonClick} className='button small c-dark bg-gold w-30 flex jcc aic ma'>Подати Заявку</a>
      </div>
      <div className="s-120"></div>
    </div>
    </>
  )
}

export {Volunteers}
