import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../parts/Header'
import cases from '../assets/img/cases.webp'

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    'event_callback': callback
  })
  return false
}

const handleButtonClick_1 = () => {
  gtag_report_conversion('https://docs.google.com/document/d/1smZrjroHGutaBkHMKra2AQE2PkOWJB8h1jCTOlTOBp0/edit?usp=sharing')
}
const handleButtonClick_2 = () => {
  gtag_report_conversion('https://docs.google.com/document/d/1LiOGn6zlWkyKY-xDrImBAiNnyDyjQecj8iPR201LE4M/edit?usp=sharing')
}
const handleButtonClick_3 = () => {
  gtag_report_conversion('https://docs.google.com/document/d/1COO-zV__CuAyqyCqSxAhTQ4fBCuGMDF6Oj3lICHjB24/edit?usp=sharing')
}
const handleButtonClick_4 = () => {
  gtag_report_conversion('https://docs.google.com/document/d/1ARCiEdbcSpMWYEivjb5kI8W6XNpqNpDcQO4t8A9fz28/edit?usp=sharing')
}

const Cases = () => {
  return (
    <>
      <Helmet>
        <title>Advogram | Кейси</title>
        <meta name="description" content="Конфлікти з ТЦК: Рішення Суду та Різниця У Регіонах. Судові справи щодо ТЦК за незаконне утримання особи: огляд рішень та різниця в підходах" />
        <meta name="keywords" content="ADVOGRAM, Кейси, Конфлікти з ТЦК, Рішення Суду, Різниця у регіонах, Судові справи, Незаконне утримання особи, Огляд рішень, Справа у Чернівцях, Апеляція у Луцьку, Справа про відмову в скаргу, Стаття 206 КПКУ, Правові спори" />
        <meta name="theme-color" content="#0C1125" />
        <body className='page' />
      </Helmet>
      <Header back={cases} />
      <div id='cases'>
        <div className="h-156"></div>
        <div className='container tc'>
          <h3 className='title-intro c-gold w-80 ma'>Кейси</h3>
        </div>
        <div className="h-156"></div>
      </div>
      <section id="mission">
        <div className="container">
          <div className="s-120"></div>
          <h3 className='title-sub c-gold w-80 ma my-3 tc'>Конфлікти з ТЦК: Рішення Суду та Різниця У Регіонах</h3>
          <p className='c-dark w-70 ma tc'>Судові справи щодо ТЦК за незаконне утримання особи: огляд рішень та різниця в підходах</p>
          <div className="s-60"></div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-4 tc">
              <h3 className='title-mini c-dark my-3 tc'>Справа у Чернівцях</h3>
              <div className="s-10"></div>
              <a href="https://docs.google.com/document/d/1smZrjroHGutaBkHMKra2AQE2PkOWJB8h1jCTOlTOBp0/edit?usp=sharing" target="blank"  onClick={handleButtonClick_1} className='button small c-dark bg-gold'>Ознайомитися</a>
              <div className="s-80"></div>
            </div>
            <div className="col-lg-4 tc">
              <h3 className='title-mini c-dark my-3 tc'>Справа про відмову в скаргу</h3>
              <div className="s-10"></div>
              <a href="https://docs.google.com/document/d/1LiOGn6zlWkyKY-xDrImBAiNnyDyjQecj8iPR201LE4M/edit?usp=sharing" target="blank"  onClick={handleButtonClick_2} className='button small c-dark bg-gold'>Ознайомитися</a>
              <div className="s-80"></div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2"></div>
            <div className="col-lg-4 tc">
              <h3 className='title-mini c-dark my-3 tc'>Апеляція у Луцьку</h3>
              <div className="s-20"></div>
              <a href="https://docs.google.com/document/d/1COO-zV__CuAyqyCqSxAhTQ4fBCuGMDF6Oj3lICHjB24/edit?usp=sharing" target="blank"  onClick={handleButtonClick_3} className='button small c-dark bg-gold'>Ознайомитися</a>
              <div className="s-80"></div>
            </div>
            <div className="col-lg-4 tc">
              <h3 className='title-mini c-dark my-3 tc'>Стаття 206 КПКУ від 13.04.2012</h3>
              <div className="s-10"></div>
              <a href="https://docs.google.com/document/d/1ARCiEdbcSpMWYEivjb5kI8W6XNpqNpDcQO4t8A9fz28/edit?usp=sharing" target="blank"  onClick={handleButtonClick_4} className='button small c-dark bg-gold'>Ознайомитися</a>
              <div className="s-80"></div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="s-40"></div>
        </div>
      </section>
    </>
  )
}

export { Cases }
