import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../parts/Header'
import adv from '../assets/img/adv.webp'
import advpic from '../assets/img/advpic.webp'

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    'event_callback': callback
  })
  return false
}

const handleButtonClick = () => {
  gtag_report_conversion('https://t.ly/6-D2y')
}

const Advocates = () => {
  return (
    <>
      <Helmet>
        <title>Advogram | Станьте Частиною Advogram як Адвокат</title>
        <meta name="description" content="Допомагайте захищати права громадян та розширюйте свою практику Advogram запрошує адвокатів, які прагнуть зробити внесок у захист прав людини та готові надавати юридичну допомогу тим, хто опинився у складних ситуаціях. Це унікальна можливість розширити вашу клієнтську базу та водночас допомагати людям у важливі моменти їхнього життя." />
        <meta name="keywords" content="ADVOGRAM, Станьте Частиною Advogram як Адвокат, Захист прав громадян, Розширення клієнтської бази, Соціальний вплив, Професійне зростання, Мережа адвокатів, Заповнення форми, Досвід адвоката, Спеціалізація адвоката, Підтримка проекту, Подати заявку" />
        <meta name="theme-color" content="#0C1125" />
        <body className='page' />
      </Helmet>
      <Header back={adv} />
      <div id='adv'>
        <div className="h-156"></div>
        <div className='container tc'>
          <h3 className='title-intro c-gold w-80 ma'>Станьте Частиною Advogram як Адвокат</h3>
        </div>
        <div className="h-156"></div>
      </div>
      <section id="mission">
        <div className="container">
          <div className="s-120"></div>
          <h3 className='title-sub c-gold w-80 ma my-3 tc'>Допомагайте захищати права громадян та розширюйте свою практику</h3>
          <p className='c-dark w-70 ma tc'>Advogram запрошує адвокатів, які прагнуть зробити внесок у захист прав людини та готові надавати юридичну допомогу тим, хто опинився у складних ситуаціях. Це унікальна можливість розширити вашу клієнтську базу та водночас допомагати людям у важливі моменти їхнього життя.</p>
          <div className="s-60"></div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <img src={advpic} alt='Станьте Частиною Advogram як Адвокат' width={400} height={480} className='obfc' />
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5 flex jcc aic">
              <div>
                <h3 className='title-sub c-dark my-3'>Чому варто долучитися до Advogram як Адвокат</h3>
                <div className="s-10"></div>
                <h4 className='title-mini c-gold my-3'>Розширення Клієнтської Бази:</h4>
                <p className='c-dark'>
                  Отримуйте доступ до нових клієнтів, які потребують юридичної допомоги.
                </p>
                <h4 className='title-mini c-gold my-3'>Соціальний Вплив:</h4>
                <p className='c-dark'>
                  Вносьте свій вклад у захист прав і свобод, допомагаючи тим, хто найбільше цього потребує.
                </p>
                <h4 className='title-mini c-gold my-3'>Професійне Зростання:</h4>
                <p className='c-dark'>
                  Розвивайте свою практику, працюючи з різноманітними юридичними випадками.
                </p>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
        <div className="s-120"></div>
        <h3 className='title-sub c-gold w-80 ma my-3 tc'>Приєднуйтесь до Нашої Мережі Адвокатів</h3>
        <p className='c-dark w-70 ma tc'>Щоб стати частиною мережі адвокатів Advogram, заповніть форму нижче. Розкажіть нам про ваш досвід, спеціалізацію та мотивацію долучитися до проекту. Ми шукаємо професіоналів, готових надавати високоякісні юридичні послуги та підтримувати нашу місію.</p>
        <div className="s-100"></div>
        <div id="zayvka">
          <div className="s-120"></div>
          <div className="container tc">
            <h3 className='title-section c-white w-80 ma my-3 tc'>Подати Заявку на Долучення</h3>
            <div className="s-60"></div>
            <a href="https://t.ly/6-D2y" target="blank" onClick={handleButtonClick} className='button small c-dark bg-gold w-30 flex jcc aic ma'>Подати Заявку</a>
          </div>
          <div className="s-120"></div>
        </div>
      </section>
    </>
  )
}

export {Advocates}
