import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../parts/Header'
import not from '../assets/img/not.webp'

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Advogram | Помилка 404 - Сторінка не знайдена</title>
        <meta name="description" content="Вибачте, сторінку, яку ви шукаєте, не знайдено. Перевірте URL-адресу або скористайтеся навігацією для пошуку потрібної інформації. Advogram - платформа для захисту прав громадян та надання юридичної допомоги." />
        <meta name="keywords" content="ADVOGRAM, Сторінка не знайдена, Помилка 404, Платформа для захисту прав громадян, Юридична допомога, Пошук інформації, Навігація" />
        <meta name="theme-color" content="#008f68" />
        <body className='page' />
      </Helmet>
      <Header back={not} />
      <div id='error'>
        <div className="h-156"></div>
        <div className='container tc'>
          <h3 className='title-intro c-gold w-80 ma'>Помилка 404. Сторінка не знайдена</h3>
        </div>
        <div className="h-156"></div>
      </div>
      <section id="mission">
        <div className="container">
          <div className="s-120"></div>
          <h3 className='title-sub c-gold w-80 ma my-3 tc'>Сторінка не знайдена</h3>
          <p className='c-dark w-70 ma tc'>Вибачте, сторінку, яку ви шукаєте, не знайдено. Перевірте URL-адресу або скористайтеся навігацією для пошуку потрібної інформації. Advogram - платформа для захисту прав громадян та надання юридичної допомоги.</p>
          <div className="s-40"></div>
          <p className='c-dark w-50 ma tc'>Запрошуємо вас повернутися на головну сторінку сайту для детального ознайомлення з усіма матеріалами.</p>
          <div className="s-40"></div>
          <a href="/" className='button small c-dark bg-gold w-30 flex jcc aic ma'>Повернутися на головну</a>
          <div className="s-120"></div>
        </div>
      </section>
    </>
  )
}

export {NotFound}
