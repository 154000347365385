import React from 'react'
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit'

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    'event_callback': callback
  })
  return false
}

const handleButtonClick = () => {
  gtag_report_conversion('https://t.ly/sHcUB')
}

const Faq = () => {
  return (
    <section id="faq">
      <div className="container">
        <div className="s-120"></div>
        <h3 className='title-section c-gold w-50 ma my-3 tc'>Питання та Відповіді</h3>
        <div className="s-20"></div>
        <div className="w-80 m-100 ma">
          <MDBContainer className="mt-5">
            <MDBAccordion alwaysOpen initialActive={1}>
              <MDBAccordionItem
                className='c-theme'
                collapseId={1}
                headerTitle="Чому важно уникати підписування повісток, які приносять у ситуації, подібній до викрадення на військову службу?"
              >
                <p className="c-dark text-faq">Підписавши повістку, людина може легалізувати дії представників військкомату, але відмова від підпису може стати підставою для подальшого обговорення законності цих дій.</p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-theme'
                collapseId={2}
                headerTitle="Чи можуть люди ознайомитися з документами або правами, які є у випадку незаконної доставки в військкомат?"
              >
                <p className="c-dark text-faq">Так, всі громадяни мають право на доступ до документів і консультації з юристами в разі незаконної доставки в військкомат. Це може включати розгляд документів про медичну комісію, повісток і інших юридичних аспектів.</p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-theme'
                collapseId={3}
                headerTitle="Які конкретні кроки можна підійняти в разі подібної ситуації та як звернутися за допомогою до юристів?"
              >
                <p className="c-dark text-faq">У таких випадках важливо звернутися до юристів та отримати юридичні поради, а також додержуватися процедур оскарження відправлення на військову службу через суд та надати докази щодо незаконності дій ТЦК.</p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-theme'
                collapseId={4}
                headerTitle="Як запобігти провокаціям та залякуванням у випадках незаконної доставки до військкомату?"
              >
                <p className="c-dark text-faq">Громадяни мають розуміти свої права, уникати недоречних дій та звертатися за консультацією до фахівців і юристів, а також дотримуватися процедур звернення в суд для захисту своїх прав у разі незаконних дій ТЦК.</p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-theme'
                collapseId={5}
                headerTitle="Які дії можливі після проходження медкомісії?"
              >
                <p className="c-dark text-faq">Після медкомісії особа фактично легалізує всі дії, відкидаючи можливість заявляти про незаконну доставку. Особливі юридичні наслідки для когось практично не настають, оскільки випадки подальших юридичних кроків є досить рідкісними на практиці.</p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-theme'
                collapseId={6}
                headerTitle="Чому більшість справ по статті 206 не розглядаються?"
              >
                <p className="c-dark text-faq">90% справ, де заявлена стаття 206, не доходять до судового розгляду через відмову від подальшого розгляду. Це вказує на те, що особа, яка подала заяву, вже на свободі. Це правило діє до того моменту, поки особа не пройде медкомісію, легалізуючи усі дії, які сталися з нею.</p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-theme'
                collapseId={7}
                headerTitle="Як можу я захистити свої права, якщо мене незаконно затримали і спробували відправити на війну?"
              >
                <p className="c-dark text-faq">Вам слід уважно ознайомитися з документами, звертатися до юристів та дотримуватися правових процедур. Підписування повістки, яку вам надають незаконно, необхідно уникати. Якщо вас примушують проходити медичний огляд або вас незаконно забирають на війну, є певні статті законодавства, які дозволяють обжалувати такі дії з боку військовкомату.</p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-theme'
                collapseId={8}
                headerTitle="Що робити, якщо мене примушують підписувати повістку на військовий медогляд?"
              >
                <p className="c-dark text-faq">За законом, ви маєте право відмовитися підписувати повістку, якщо вона надається вам незаконно. Дуже важливо звернутися до юристів для консультації та правової підтримки у таких ситуаціях. Вам необхідно перейти у відповідний пункт додатку Advogram, де ви зможете уважно ознайомитись з власними правами та документами які підтверджують ваш статус.</p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className='c-theme'
                collapseId={9}
                headerTitle="Чи є можливість обійти примусове відправлення на війну, якщо повістка була вручена неправомірно?"
              >
                <p className="c-dark text-faq">Якщо повістка була надана вам незаконно або у вас є підстави вважати її недійсною, ви маєте можливість обжалувати цей документ через судовий процес. Звертайтесь до відповідного пункту меню додатку Advogram щоб отримати консультації для допомоги у цій справі.</p>
              </MDBAccordionItem>
            </MDBAccordion>
          </MDBContainer>
        </div>
        <div className="s-60"></div>
        <a href="https://t.ly/sHcUB" target="blank" onClick={handleButtonClick} className='button small c-dark bg-gold w-40 flex jcc aic ma'>Завантажити додаток Advogram</a>
        <div className="s-120"></div>
      </div>
    </section>
  )
}

export { Faq }
