import React from 'react'

const Join = () => {
  return (
    <section id="mission" className='bg-light'>
      <div className="container">
        <div className="s-120"></div>
        <h3 className='title-section c-dark w-50 ma my-3 tc'>Чому Варто Долучитися до Нас</h3>
        <div className="s-60"></div>
        <div className="row">
          <div className="col-lg-4 tc">
            <div className="mx-3">
              <h4 className="title-icon">💪</h4>
              <h3 className="title-sub c-dark">Волонтерам</h3>
              <h5 className='title-mini c-dark'>Переваги</h5>
              <div className="s-20"></div>
              <p className="c-dark">Ставши волонтером Advogram, ви робите значний внесок у захист прав і свобод громадян. Ваша допомога у розробці, дизайні та підтримці платформи допоможе людям у критичних ситуаціях. Використання передових технологій, включаючи штучний інтелект для підбору адвокатів, робить ваш внесок ще ціннішим.</p>
            </div>
          </div>
          <div className="col-lg-4 tc">
            <div className="mx-3">
              <h4 className="title-icon">🏛️</h4>
              <h3 className="title-sub c-dark">Адвокатам</h3>
              <h5 className='title-mini c-dark'>Значення</h5>
              <div className="s-20"></div>
              <p className="c-dark">Долучення до Advogram як адвоката - це не лише можливість допомагати людям у важливі моменти їхнього життя, але й шанс розширити вашу клієнтську базу. Ви отримуєте доступ до широкого спектру клієнтів, які потребують кваліфікованої юридичної допомоги, тим самим збільшуючи ваш професійний вплив та визнання.</p>
            </div>
          </div>
          <div className="col-lg-4 tc">
            <div className="mx-3">
              <h4 className="title-icon">🛡️</h4>
              <h3 className="title-sub c-dark">Користувачам</h3>
              <h5 className='title-mini c-dark'>Допомога</h5>
              <div className="s-20"></div>
              <p className="c-dark">Advogram створений, щоб допомогти вам у випадках неправомірних дій з боку РТЦК. Наша платформа забезпечує швидкий доступ до досвідчених адвокатів, готових надати вам необхідну юридичну підтримку та захист. З Advogram ви завжди можете відчувати себе захищеним і впевненим у своїх правах.</p>
            </div>
          </div>
        </div>
        <div className="s-120"></div>
      </div>
    </section>
  )
}

export { Join }
