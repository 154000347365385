import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../parts/Header'
import thankyou from '../assets/img/thankyou.webp'

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    'event_callback': callback
  })
  return false
}

const handleButtonClick = () => {
  gtag_report_conversion('/')
}

const Thankyou = () => {

  return (
    <>
      <Helmet>
        <title>Advogram | Дякуємо, що обрали нас!</title>
        <meta name="description" content="Дякуємо за зворотній зв'язок. Ми зв'яжемося з вами найближчим часом. Запрошуємо вас повернутися на головну сторінку сайту для детального ознайомлення з усіма матеріалами. Конфлікти з ТЦК: Рішення Суду та Різниця У Регіонах. Судові справи щодо ТЦК за незаконне утримання особи: огляд рішень та різниця в підходах" />
        <meta name="keywords" content="ADVOGRAM, Юридична підтримка, РТЦК, Громадський стартап, Неправомірні дії, Легальна допомога, Адвокати, Правозахист, Юридична інформація, Волонтерство, Адвокатська мережа, Швидка реакція, Кваліфікована консультація, Судові справи, Захист прав, Волонтерська діяльність, Штучний інтелект, Справедливість, Права громадян, Ефективний захист, Правова свідомість, Інформаційна підтримка, Юридичний захист" />
        <meta name="theme-color" content="#0C1125" />
        <body className='page' />
      </Helmet>
      <Header back={thankyou} />
      <div id='thankyou'>
        <div className="h-156"></div>
        <div className='container tc'>
          <h3 className='title-intro c-gold w-80 ma'>Дякуємо, що обрали нас!</h3>
        </div>
        <div className="h-156"></div>
      </div>
      <section id="mission">
        <div className="container">
          <div className="s-120"></div>
          <h3 className='title-sub c-gold w-80 ma my-3 tc'>Дякуємо за зворотній зв'язок</h3>
          <p className='c-dark w-70 ma tc'>Ми зв'яжемося з вами найближчим часом.</p>
          <div className="s-40"></div>
          <p className='c-dark w-50 ma tc'>Запрошуємо вас повернутися на головну сторінку сайту для детального ознайомлення з усіма матеріалами.</p>
          <div className="s-40"></div>
          <a href="/" onClick={handleButtonClick} className='button small c-dark bg-gold w-30 flex jcc aic ma'>Повернутися на головну</a>
          <div className="s-120"></div>
        </div>
      </section>
    </>
  )
}

export { Thankyou }
