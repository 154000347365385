import React from 'react'

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    'event_callback': callback
  })
  return false
}

const handleButtonClick_1 = () => {
  gtag_report_conversion('https://t.ly/w8CPB')
}

const handleButtonClick_2 = () => {
  gtag_report_conversion('https://t.ly/atak9')
}

const Features = () => {
  return (
    <section id="features">
      <div className="container tc">
        <div className="s-120"></div>
        <h3 className='title-section c-gold w-50 ma my-3 tc'>Важливо!</h3>
        <p className='c-dark w-70 ma tc'>Кнопка SOS в Advogram - це швидкий спосіб звернення за юридичною допомогою. Натиснувши її, ви миттєво відправляєте запит на юридичну консультацію, і система автоматично аналізує ваше місцезнаходження, щоб знайти найближчих адвокатів, готових надати допомогу.</p>
        <div className="s-60"></div>
        <a href="https://t.ly/w8CPB" target="blank" onClick={handleButtonClick_1} className='button small c-dark bg-gold w-30 flex jcc aic ma'>S O S</a>
        <div className="s-120"></div>
        <div className="row">
          <div className="col-lg-6">
            <div className="mx-5">
              <h3 className="title-sub c-gold flex jcfs aic">
                <span className="title-sub">⚖️</span>
                <em className="w-3"></em>
                Вибір Адвоката
              </h3>
              <p className="c-dark tl">Advogram використовує розширені алгоритми для вибору найкращого адвоката для вашої ситуації. Система враховує спеціалізацію адвокатів, їхній досвід та відгуки клієнтів, забезпечуючи вам доступ до професіоналів, які найкраще відповідають вашим потребам.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mx-5">
              <h3 className="title-sub c-gold flex jcfs aic">
                <span className="title-sub">🚀 </span>
                <em className="w-3"></em>
                Швидка Реакція
              </h3>
              <p className="c-dark tl">Advogram гарантує швидку реакцію від адвокатів. Наша мережа юристів завжди готова надати вам оперативну допомогу. Незалежно від часу доби або складності вашого запиту, ви можете розраховувати на швидку та ефективну юридичну підтримку.</p>
            </div>
          </div>
        </div>
        <div className="s-60"></div>
        <h3 className='title-section c-gold w-80 ma my-3 tc'>Долучитися до Команди</h3>
        <div className="s-20"></div>
        <p className='c-dark w-70 ma tc'>Якщо ви хочете зробити внесок у захист прав і свобод громадян, Advogram відкритий для вас. Ми шукаємо волонтерів та адвокатів, які готові долучитися до нашої місії. Волонтери можуть допомагати нам у розробці, дизайні, маркетингу та інших аспектах проекту. Адвокати можуть приєднатися до нашої мережі, щоб надавати юридичну допомогу тим, хто її потребує. Разом ми можемо створити сильну спільноту, здатну захищати права кожного.</p>
        <div className="s-60"></div>
        <a href="https://t.ly/atak9" target="blank" onClick={handleButtonClick_2} className='button small c-dark bg-gold w-30 flex jcc aic ma'>Долучитися</a>
        <div className="s-120"></div>
      </div>
    </section>
  )
}

export { Features }
