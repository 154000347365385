import React from 'react'

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    'event_callback': callback
  })
  return false
}

const handleButtonClick = () => {
  gtag_report_conversion('https://t.ly/w8CPB')
}

const Intro = () => {
  return (
    <div id='hero'>
      <div className="h-156"></div>
      <div className='container tc'>
        <div className='s-20'></div>
        <h3 className='title-intro c-gold w-80 ma'>ADVOGRAM - ГРОМАДСЬКИЙ СТАРТАП ЗАХИСТУ ВІД РТЦК</h3>
        <h4 className='title-intro-sub my-3 w-90 ma c-white'>Миттєво отримуйте кваліфіковану юридичну підтримку у випадках конфлікту з РТЦК</h4>
        <div className='s-40'></div>
        <a href="https://t.ly/w8CPB" target="blank" onClick={handleButtonClick} className='button small c-dark bg-gold' rel="noreferrer">Захистіть свої права негайно</a>
      </div>
      <div className="h-156"></div>
    </div>
  )
}

export { Intro }
