import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from './pages/Home'
import { Cases } from './pages/Cases'
import { Advocates } from './pages/Advocates'
import { Volunteers } from './pages/Volunteers'
import { Thankyou } from './pages/Thankyou'
import { NotFound } from './pages/NotFound'
import { Footer } from './parts/Footer'
import './styles/custom.sass'

function App() {

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='cases' element={<Cases />} />
          <Route path='advocates' element={<Advocates />} />
          <Route path='volunteers' element={<Volunteers />} />
          <Route path='thankyou' element={<Thankyou />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </HelmetProvider>
  )
}

export {App}
