import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../parts/Header'
import { Intro } from '../parts/Intro'
import { Misson } from '../parts/Misson'
import { Join } from '../parts/Join'
import { Features } from '../parts/Features'
import { Cta } from '../parts/Cta'
import { Faq } from '../parts/Faq'
import hero from '../assets/img/hero.webp'

const Home = () => {

  return (
    <>
      <Helmet>
        <title>Advogram | Громадський стартап захисту від РТЦК</title>
        <meta name="description" content="Advogram створений, щоб надавати кожному доступ до кваліфікованої юридичної допомоги у випадках неправомірних дій РТЦК. Ми прагнемо зміцнити правову свідомість громадян та захистити їхні права, забезпечуючи оперативний зв'язок з досвідченими адвокатами у критичних ситуаціях. Наша мета - створити справедливе суспільство, де кожен може відчувати себе захищеним і підтриманим." />
        <meta name="keywords" content="ADVOGRAM, Юридична підтримка, РТЦК, Громадський стартап, Неправомірні дії, Легальна допомога, Адвокати, Правозахист, Юридична інформація, Волонтерство, Адвокатська мережа, Швидка реакція, Кваліфікована консультація, Судові справи, Захист прав, Волонтерська діяльність, Штучний інтелект, Справедливість, Права громадян, Ефективний захист, Правова свідомість, Інформаційна підтримка, Юридичний захист" />
        <meta name="theme-color" content="#0C1125" />
        <body className="home" />
      </Helmet>
      <Header back={hero} />
      <Intro />
      <Misson />
      <Join />
      <Features />
      <Cta />
      <Faq />
    </>
  )
}

export { Home }
