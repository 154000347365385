import React from 'react'

const Cta = () => {
  return (
    <section id="cta">
      <div className="container tc">
        <div className="s-120"></div>
         <h3 className='title-section c-white w-80 ma my-3 tc'>Судові Справи</h3>
        <div className="s-20"></div>
        <p className='c-white w-70 ma tc'>У цій секції ви знайдете реальні історії та кейси, де Advogram вже допоміг людям захистити свої права в судових спорах з РТЦК. Ці історії успіху демонструють, як наша платформа та мережа кваліфікованих адвокатів ефективно допомагають громадянам у вирішенні юридичних проблем, пов'язаних з незаконними діями РТЦК. Вони служать свідченням нашої відданості місії захисту прав людини та підтримки справедливості.</p>
        <div className="s-60"></div>
        <a href="https://www.advogram.com/cases" className='button small c-dark bg-gold w-30 flex jcc aic ma'>Кейси</a>
        <div className="s-120"></div>
      </div>
    </section>
  )
}

export { Cta }
