import React from 'react'

const Misson = () => {
  return (
    <section id="mission">
      <div className="container">
        <div className="s-120"></div>
        <h3 className='title-section c-gold w-50 ma my-3 tc'>Наша місія - забезпечення швидкого та ефективного юридичного захисту</h3>
        <p className='c-dark w-70 ma tc'>Advogram створений, щоб надавати кожному доступ до кваліфікованої юридичної допомоги у випадках неправомірних дій РТЦК. Ми прагнемо зміцнити правову свідомість громадян та захистити їхні права, забезпечуючи оперативний зв'язок з досвідченими адвокатами у критичних ситуаціях. Наша мета - створити справедливе суспільство, де кожен може відчувати себе захищеним і підтриманим.</p>
        <div className="s-60"></div>
        <h3 className='title-section c-gold w-80 ma my-3 tc'>Чому ADVOGRAM?</h3>
        <p className="title-mini w-60 ma tc c-gold">Незаконні Дії РТЦК</p>
        <p className='c-dark w-70 ma tc'>У випадках незаконного затримання або примусу до військової служби РТЦК, Advogram надає негайну юридичну підтримку. Ми допомагаємо громадянам захистити свої права, забезпечуючи доступ до досвідчених адвокатів, які спеціалізуються на подібних випадках.</p>
        <div className="s-60"></div>
        <div className="row">
          <div className="col-lg-6">
            <div className="mx-5">
              <h3 className="title-sub c-gold">Сервіс Правозахисту</h3>
              <p className="c-dark">Advogram створений для тих, хто опинився у складній ситуації і потребує юридичної допомоги, але не має особистого адвоката. Наша платформа забезпечує швидкий доступ до кваліфікованих юристів, готових надати професійну консультацію та захист у будь-який час.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mx-5">
              <h3 className="title-sub c-gold">Юридична Інформація</h3>
              <p className="c-dark">Advogram - це проект громадської організації «АДВОГРАМ», очолюваної Наталею Кокоріною, юристом за фахом. Ми працюємо на основі прозорості та відповідальності, прагнучи забезпечити ефективний юридичний захист для кожного.</p>
            </div>
          </div>
        </div>
        <div className="s-120"></div>
      </div>
    </section>
  )
}

export {Misson}
